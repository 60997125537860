<template>
  <section id="card-navigation">
    <b-card>
      <h4></h4>

      <div class="blue box ex2">
        <div class="coral item">
          <h3 class="pink item" style="margin-top: -10px">
            รายชื่อสมาชิกที่สามารถใช้บัญชีนี้ได้
          </h3>
        </div>
        <div class="coral item">
          <!-- <span >Link 1</span> -->
          <div class="pink item" style="margin-top: -10px">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-modal.modal-no-backdrop
            >
              เพิ่มสมาชิก
            </b-button>
          </div>
        </div>
      </div>

      <b-row>
        <b-col cols="12">
          <hr />
          จำนวน {{ totalRows }}
          <div
            class="custom-search d-flex justify-content-end"
            style="margin-top: -2em"
          >
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'name'">
                <b-avatar size="lg" :src="props.row.profile" />
                <b> {{ props.row.name }} </b>
              </div>
              <div v-if="props.column.field === 'status'">
                <b> {{ props.row.status }} </b>
              </div>

              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="edit_user(props.row.id)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>แก้ไข</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        del_user(props.row.id, props.row.name, props.row.email)
                      "
                    >
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>ลบ</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '15', '20']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-no-backdrop"
      hide-backdrop
      ok-only
      centered
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="การจัดการสิทธิ์"
      ok-title="Accept"
      v-model="model_gen"
    >
      <b-card-text>
        <b-card-text>
          <span style="font-size: 13px">
            คุณสามารถมอบสิทธิ์ให้แก่สมาชิกด้วยการออกลิงก์มอบสิทธิ์แล้วส่งให้กับสมาชิกคนนั้น </span
          ><br />
          <code>ประเภทสิทธิ์</code>
        </b-card-text>
        <b-form-group>
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="option"
          />
        </b-form-group>
        <b-input-group>
          <b-form-input
            placeholder="ลิงค์นี้มีเวลาใช้งาน 24 ชั่วโมงและสามารถใช้ได้เพียงครั้งเดียว"
            readonly
            v-model="url"
            id="myInput"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-clipboard:copy="url"
              @click="gen_link()"
            >
              คัดลอก
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <br />
        <div align="center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="model_gen = false"
          >
            ปิด
          </b-button>
        </div>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BAvatar,
  VBModal,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import vSelect from "vue-select";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BAvatar,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    vSelect,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      selected: { title: "แอดมิน" },
      model_gen: false,
      url: null,
      option: [
        { title: "แอดมิน" },
        { title: "โอเปอเรเตอร์" },
        // { title: "โอเปอเรเตอร์ (ไม่มีสิทธิ์บรอดแคสต์)" },
        // { title: "โอเปอเรเตอร์ (ไม่มีสิทธิ์ดูข้อมูลเชิงลึก)" },
      ],
      dataSplashPage: null,
      totalSplashPage: null,
      totalRows: "",
      rows: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "สมาชิก",
          field: "name",
        },

        {
          label: "สิทธ์",
          field: "status",
        },

        {
          label: "",
          field: "action",
        },
      ],
      message: 'The text inside this text input will be copied to the clipboard',
      searchTerm: "",
    };
  },
  mounted() {
    this.loaddata();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    gen_link() {
      var userData = JSON.parse(localStorage.getItem("userData"));

      const params = {
        user_main: userData.user_main,
        role: this.selected.title,
        store_id: localStorage.getItem("store_id"),
      };
      console.log("params :>> ", params);
      api
        .post("/genurl", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("object :>> ", response.data);
          this.url = response.data;
          navigator.clipboard.writeText(this.url);
        })
        .catch((error) => {});
    },
    async loaddata() {
      var userData = JSON.parse(localStorage.getItem("userData"));
      //  console.log('userData :>> ', userData);
      const params = {
        user_main: userData.user_main,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("get_users_in_store", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.rows = response.data.users;
          this.totalRows = response.data.total;
        });
    },
    del_user(id) {
      const params = {
        id: id,
        store_id: localStorage.getItem("store_id"),
      };
      console.log("del :>> ", id);
      var userData = JSON.parse(localStorage.getItem("userData"));
      userData.user_main;
      console.log(" userData.user_main :>> ", userData.user_main);
      if (userData.user_main != id) {
        this.$swal({
          title: "คุณต้องการลบผู้ใช้งาน ?",
          text: "คุณจะไม่สามารถย้อนกลับได้!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            api
              .post("/users_store_del", params, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("users_store_del", response);
                if (response) {
                  this.loaddata();
                  this.$swal({
                    icon: "success",
                    title: "ลบสำเร็จ!",
                    customClass: {
                      confirmButton: "btn btn-success",
                    },
                  });
                }
              });
          }
        });
      } else {
        this.$swal({
          title: "ผู้ใช้งานหลัก ไม่สามารถลบได้",
          timer: 3000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    edit_user(id, name, email_up) {
      this.update = true;
      console.log("id :>> ", id);
      console.log("name :>> ", name);
      console.log("userEmail :>> ", email_up);
      this.id = id;
      this.username_up = name;
      this.email_up = email_up;
      this.password_up = null;
      this.password_up_con = null;
      console.log("this.id :>> ", this.id);
      console.log("this.username_up :>> ", this.username_up);
    },
    update_user() {
      console.log("this.id :>> ", this.id);
      console.log("this.username_up :>> ", this.username_up);
      console.log("this.password_up :>> ", this.password_up);
      console.log("this.password_up_con :>> ", this.password_up_con);
      // update_user
      const params = {
        id: this.id,
        email: this.email_up,
        username_up: this.username_up,
        password_up: this.password_up,
        password: this.password_up_con,
      };

      api
        .post("/update_user", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("object :>> ", response.data);
          // this.data = response.data;
        })
        .catch((error) => {});
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกสำเร็จ',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'ไม่สามารถคัดลอกข้อความ!',
          icon: 'BellIcon',
        },
      })
    },

  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-table.bordered td {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
}
.vgt-table.bordered th {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  background-color: #edeff0;
}
.ex2 {
  display: flex;
  justify-content: space-between;
}
</style>

